
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import FileView from '@/components/core/files/FileView.vue'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'
  import { IProcessAttachment } from '@/entities'
  import { Debounce } from '@/utils/decorators'

@Component({
  components: { FileView, FieldTitle },
})
  export default class Attachment extends FilesProcess {
  @Prop({ type: Array, default: () => null }) value!: IProcessAttachment[]
  @Prop({ type: String, default: '' }) title: string

  files = []
  idProcess = null
  documents = []

  mounted () {
  }

  openFile (fileProcess) {
    this.openLink(fileProcess.file.uri)
  }

  async findAssociatedFiles ({ idProcessRecord, allowedFiles, tableName }: IProcessAttachment) {
    if (!idProcessRecord || !tableName) return {}
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: tableName } },
    })

    const idProcess = process?.[0]?.id
    const filter = {
      _and: [
        { id_process_record: { _eq: idProcessRecord } },
        { parameter: { process: { id: { _eq: idProcess } } } },
        { parameter: { name: { _in: allowedFiles } } },
      ],
    }
    if (!allowedFiles?.length) {
      filter._and.splice(2, 1)
    }
    return this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter,
      force: true,
    })
  }

  async setPhotoToForm (idProcessRecord, idProcess) {
    if (!idProcessRecord) return

    return this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: idProcessRecord } },
          { parameter: { process: { id: { _eq: idProcess } } } },
          { parameter: { file_type: { name: { _eq: 'photo' } } } },
        ],
      },
      force: true,
    })
  }

  @Debounce()
  @Watch('value', { deep: true, immediate: true })
  async updateValue (val: IProcessAttachment[]) {
    if (!val?.length || !val?.filter(_ => _)?.length) return

    this.documents = (await Promise.all(
      val.map(async processAttachment => {
        return this.findAssociatedFiles(processAttachment)
      })
    )).flat()
  }
  }
