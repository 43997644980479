import { Component } from 'vue-property-decorator'
import { isValidNumber, parseToNumber } from '@/utils/general'
import { AppraisalView } from '@/components/forms/view/AppraisalView'
import { FileProcess } from '@/entities/files'

@Component
export class NegotiationView extends AppraisalView {
  statusNegotiation = {
    appealed: null,
    appealedAnswered: null,
    readingFile: null,
    approved: null,
    rejected: null,
    closed: null,
    legalReview: null,
    toUpdate: null,
  }

  closingReasonNegotiation = {
    inValidDocument: null,
    success: null,
  }

  async mounted () {
    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'negotiation' } } }] },
    })

    this.statusNegotiation.appealed = status.filter(status => status.status.name === 'appealed')
    this.statusNegotiation.appealedAnswered = status.filter(status => status.status.name === 'appeal_answered')
    this.statusNegotiation.readingFile = status.filter(status => status.status.name === 'reading_file')
    this.statusNegotiation.approved = status.filter(status => status.status.name === 'approved')
    this.statusNegotiation.rejected = status.filter(status => status.status.name === 'rejected')

    const filter = {
      _and: [
        { type: { name: { _eq: 'invalid_document' } } },
        { status: { process: { table_name: { _eq: 'negotiation' } } } },
      ],
    }

    this.closingReasonNegotiation.inValidDocument = await this.fetchData({ query: { name: 'find', model: 'ClosingReason' }, filter })

    this.statusNegotiation.closed = status.filter(status => status.status.name === 'closed')

    const filterSuccess = {
      _and: [
        { type: { name: { _eq: 'successful' } } },
        { status: { process: { table_name: { _eq: 'negotiation' } } } },
      ],
    }

    this.closingReasonNegotiation.success = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: filterSuccess,
    })

    this.statusNegotiation.legalReview = status.filter(status => status.status.name === 'legal_review')
    this.statusNegotiation.toUpdate = status.filter(status => status.status.name === 'to_update')
  }

  async insertNegotiationAppealed (formData, negotiation, idEmployee) {
    const { expectedPrice, expectedPriceConsignment } = formData

    let response
    let responseConsignment
    const insertAppealed = !negotiation.status.isAppealed

    if (insertAppealed) {
      response = await this.insertResponse(expectedPrice, idEmployee, negotiation, 'appealed')
      responseConsignment = await this.insertResponse(expectedPriceConsignment, idEmployee, negotiation, 'appealed', '_consignment')
    } else {
      const lastResponseAmount = negotiation?.negotiationResponseAppealed?.value
      const lastResponseValid = isValidNumber(lastResponseAmount)

      if (lastResponseValid && parseToNumber(expectedPrice) !== parseToNumber(lastResponseAmount)) {
        await this.updateResponse(expectedPrice, negotiation.negotiationResponseAppealed)
      }

      const lastResponseConsignmentAmount = negotiation?.negotiationResponseAppealedConsignment?.value
      const lastResponseConsignmentValid = isValidNumber(lastResponseConsignmentAmount)

      if (lastResponseConsignmentValid && parseToNumber(expectedPriceConsignment) !== parseToNumber(lastResponseConsignmentAmount)) {
        await this.updateResponse(expectedPriceConsignment, negotiation.negotiationResponseAppealedConsignment)
      }
    }

    if (response || responseConsignment) {
      await this.pushData({
        model: 'Negotiation',
        fields: { id: negotiation.id, id_process_status: this.statusNegotiation.appealed?.[0]?.id },
      })
    }
  }

  async insertNegotiationResponseSupervisor (formData, negotiation, idEmployee) {
    const { expectedPrice, expectedPriceConsignment } = formData

    let response
    let responseConsignment
    const insertAppealedResponse = !negotiation.status.isAppealAnswered

    if (insertAppealedResponse) {
      response = await this.insertResponse(expectedPrice, idEmployee, negotiation, 'offer')
      responseConsignment = await this.insertResponse(expectedPriceConsignment, idEmployee, negotiation, 'offer', '_consignment')
    } else {
      const lastResponseAmount = negotiation?.negotiationResponseAmount?.value
      const lastResponseValid = isValidNumber(lastResponseAmount)

      if (lastResponseValid && parseToNumber(expectedPrice) !== parseToNumber(lastResponseAmount)) {
        await this.updateResponse(expectedPrice, negotiation.negotiationResponseAmount)
      }

      const lastResponseConsignmentAmount = negotiation?.negotiationResponseAmountConsignment?.value
      const lastResponseConsignmentValid = isValidNumber(lastResponseConsignmentAmount)

      if (lastResponseConsignmentValid && parseToNumber(expectedPriceConsignment) !== parseToNumber(lastResponseConsignmentAmount)) {
        await this.updateResponse(expectedPriceConsignment, negotiation.negotiationResponseAmountConsignment)
      }
    }

    if (response || responseConsignment) {
      await this.pushData({
        model: 'Negotiation',
        fields: {
          id: negotiation.id,
          id_process_status: this.statusNegotiation.appealedAnswered?.[0]?.id,
          id_supervisor: this.idEmployee,
        },
      })
    }
  }

  async insertResponse (expectedPrice, idEmployee, negotiation, type, typeResponse = '') {
    const setType = (type + typeResponse) as 'appealed' | 'appealed_consignment' | 'offer' | 'offer_consignment'

    if (!expectedPrice) return

    return this.pushData({
      model: 'NegotiationResponse',
      fields: {
        amount: parseToNumber(expectedPrice),
        id_employee: idEmployee,
        id_negotiation: negotiation.id,
        type: setType,
      },
    })
  }

  async updateResponse (price, response) {
    if (!price || !response?.id) return

    await this.pushData({
      model: 'NegotiationResponse',
      fields: {
        id: response.id,
        amount: parseToNumber(price),
      },
    })
  }

  async insertAgreedAmount (viewData) {
    const { formData, negotiation } = viewData

    await this.pushData({
      model: 'Negotiation',
      fields: {
        id: negotiation.id,
        agreed_amount: formData.agreementPrice,
        id_process_status: this.statusNegotiation.readingFile?.[0]?.id,
      },
    })
  }

  async insertUpdateFile (viewData) {
    const {
      idProcess,
      formData: { cav, legalReport, penalties, linkLegalReport },
      fields: { cav: cavField, legalReport: legalReportField, penalties: penaltiesField },
      negotiation: { id },
    } = viewData

    if (cav?.length) {
      const fileParameters = await this.fetchData({
        query: { name: 'find', model: 'FileParameter' },
        filter: { process: { table_name: { _eq: 'negotiation' } } },
      })
      const cavParameter = fileParameters.filter(parameter => parameter.name === 'cav')
      const file = await this.handleFileType(cav, cavField, idProcess, id, cavParameter)

      if (file?.length) {
        const fileProcess = file[0] as FileProcess
        if (fileProcess?.id) {
          await this.pushData({
            model: 'Negotiation',
            fields: {
              id,
              id_process_status: this.statusNegotiation.readingFile?.[0]?.id,
            },
          })
        }
      }
    }
    if (legalReport?.length) {
      await this.handleFileType(legalReport, legalReportField, idProcess, id)
      const fileId = this.isArrayFiles(legalReport) ? legalReport[0]?.id : legalReport?.[0]?.file?.id
      const fields = { id: fileId, source_link: linkLegalReport }
      await this.pushData({ model: 'File', fields })
    }

    if (penalties?.length) {
      const file = await this.handleFileType(penalties, penaltiesField, idProcess, id)
      if (file?.length) {
        const fileProcess = file[0] as FileProcess
        if (fileProcess?.id) {
          await this.pushData({
            model: 'Negotiation',
            fields: {
              id,
              id_process_status: this.statusNegotiation.readingFile?.[0]?.id,
            },
          })
        }
      }
    }

    await this.processCavAndTickets(id)
  }

  async negotiationHandleStatus (viewData) {
    const { formData: { legalApproved, executiveUpdate, options }, formData, negotiation, pendingPenalties } = viewData
    if (legalApproved && !pendingPenalties) {
      await this.pushData({
        model: 'Negotiation',
        fields: {
          id: negotiation.id,
          id_process_status: this.statusNegotiation.approved?.[0]?.id,
          id_supervisor: this.idEmployee,
        },
      })

      await this.changeValidFileProcess(formData.cav[0])
      await this.changeValidFileProcess(formData.legalReport[0])
      await this.changeValidFileProcess(formData.penalties[0])
    } else {
      if (!executiveUpdate && !pendingPenalties) {
        await this.pushData({
          model: 'Negotiation',
          fields: {
            id: negotiation.id,
            id_process_status: this.statusNegotiation.rejected?.[0]?.id,
            id_closing_reason: this.closingReasonNegotiation.inValidDocument?.[0]?.id,
            id_supervisor: this.idEmployee,
          },
        })
        await this.changeValidFileProcess(formData.cav[0], false)
        await this.changeValidFileProcess(formData.legalReport[0], false)
        await this.changeValidFileProcess(formData.penalties[0], false)
      } else {
        await this.pushData({
          model: 'Negotiation',
          fields: {
            id: negotiation.id,
            id_process_status: this.statusNegotiation.toUpdate?.[0]?.id,
            id_supervisor: this.idEmployee,
          },
        })

        if (options?.length) {
          for (const key of options) {
            if (formData[key]?.length) {
              await this.insertFileProcessInfo({ id: formData[key][0]?.id, comment: 'Debe actualizar el archivo' })
            }
          }
        }
      }
    }
  }

  async getPenalties (idFileProcess) {
    if (!idFileProcess) return
    const processTrafficTickets = await this.fetchData({
      query: { name: 'find', model: 'ProcessTrafficTicket' },
      filter: { id_file_process: { _eq: idFileProcess } },
      force: true,
    })

    return processTrafficTickets || []
  }

  async changeStatusLegalReview (negotiation) {
    await this.pushData({
      model: 'Negotiation',
      fields: {
        id: negotiation.id,
        id_process_status: this.statusNegotiation.legalReview?.[0]?.id,
      },
    })
  }
}
