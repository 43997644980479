<template>
  <v-card class="dialog px-0 mx-0">
    <v-card-title>
      <form-title
        class="pt-2"
        title="GASTOS"
      />
    </v-card-title>

    <v-card>
      <v-card-text>
        <auto-resume
          v-if="dialogData.expense?.deal?.id"
          :deal="dialogData.expense?.deal"
          not-display-appraisal
          hide-photo
          hide-title
        />
        <v-container>
          <v-row
            justify="space-between"
            no-gutters
          >
            <b>ID Gasto</b>
            <p>{{ dialogData.expense?.id }}</p>
          </v-row>
          <v-row
            justify="space-between"
            no-gutters
          >
            <b class="bigger">Monto solicitado</b>
            <p class="bigger font-weight-bold">
              {{ dialogData.expense?.amountFixed || '-' }}
            </p>
          </v-row>
          <v-row
            justify="space-between"
            no-gutters
          >
            <b>Tipo de gasto</b>
            <p>{{ dialogData.expense?.paymentRecipient?.paymentOrderItem?.processExpense?.expense?.description || '-' }}</p>
          </v-row>
          <v-row
            justify="space-between"
            no-gutters
          >
            <b>Forma de pago</b>
            <p>{{ dialogData.expense?.type?.description || '-' }}</p>
          </v-row>
          <v-row
            justify="space-between"
            no-gutters
          >
            <b>Beneficiario</b>
            <p>{{ dialogData.expense?.paymentRecipient?.person?.alias || dialogData.expense?.paymentRecipient?.person?.shortName || '-' }}</p>
          </v-row>
          <v-row
            justify="space-between"
            no-gutters
          >
            <b>RUT</b>
            <p>{{ dialogData.expense?.paymentRecipient?.person?.uid || '-' }}</p>
          </v-row>
          <v-row
            justify="space-between"
            no-gutters
          >
            <b>Teléfono</b>
            <p>{{ dialogData.expense?.paymentRecipient?.person?.phone || dialogData.expense?.paymentRecipient?.person?.phoneWork || '-' }}</p>
          </v-row>
          <v-row
            justify="space-between"
            no-gutters
          >
            <b>Correo</b>
            <p>{{ dialogData.expense?.paymentRecipient?.person?.email?.personal || dialogData.expense?.paymentRecipient?.person?.email?.work || '-' }}</p>
          </v-row>
          <v-row
            justify="space-between"
            no-gutters
          >
            <b>Banco</b>
            <p>{{ dialogData.expense?.recipientAccount?.bankData?.bank?.alias || '-' }}</p>
          </v-row>
          <v-row
            justify="space-between"
            no-gutters
          >
            <b>N° de cuenta</b>
            <p>{{ dialogData.expense?.recipientAccount?.bankData?.accountNumber || '-' }}</p>
          </v-row>

          <v-divider />
          <v-row
            justify="space-between"
            no-gutters
          >
            <b>Solicitante</b>
            <p>{{ dialogData.expense?.deal?.lead?.executive?.person?.shortName || '-' }}</p>
          </v-row>
          <v-row
            justify="space-between"
            no-gutters
          >
            <b>Validador</b>
            <p>{{ dialogData.expense?.validator?.person?.shortName || '-' }}</p>
          </v-row>
          <v-row
            justify="space-between"
            no-gutters
          >
            <b>Pagador</b>
            <p>{{ dialogData.expense?.responsible?.person?.shortName || '-' }}</p>
          </v-row>
          <v-row
            justify="space-between"
            no-gutters
          >
            <b>Cuenta cargo</b>
            <p>{{ dialogData.financialAccount?.personAccount?.name || '-' }}</p>
          </v-row>
          <v-row
            justify="space-between"
            no-gutters
          >
            <b>Estado del pago</b>
            <p>
              <v-icon :color="dialogData.status?.color">
                {{ dialogData.status?.icon?.main }}
              </v-icon>
              {{ dialogData.status?.description }}
            </p>
          </v-row>

          <v-divider />

          <attachment
            title="Respaldos"
            :value="dialogData.processAttachment"
          />

          <v-row
            justify="center"
            class="px-4 mt-4"
          >
            <v-btn
              color="error"
              @click="dialogData.toggleDialog"
            >
              Volver
            </v-btn>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import FormTitle from '@/components/forms/FormTitle.vue'
  import AutoResume from '@/components/forms/AutoResume.vue'
  import Attachment from '@/components/toolkit/details/row/custom/Attachment.vue'

  @Component({
    components: {
      FormTitle,
      AutoResume,
      Attachment,
    },
  })
  export default class ExpenseDialog extends Vue {
    @Prop({
      default: () => ({
            expense: undefined,
            status: undefined,
            financialAccount: undefined,
            toggleDialog: undefined,
            processAttachment: undefined,
          }),
      }) dialogData
  }
</script>

<style>

</style>
